import React, { Component } from 'react';
import axios from 'axios'

export default class CreateMethod extends Component {

    state = {
        methods: [],
        method: ''
    }

    async componentDidMount() {
        this.getMethods();
    }

    getMethods = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + '/api/methods');
        this.setState({ methods: res.data })
    }

    onChangeMethod = e => {
        this.setState({
            method: e.target.value
        })
    }

    onSubmit = async e => {
        e.preventDefault();
        await axios.post(process.env.REACT_APP_BASE_URL + '/api/methods', {
            method: this.state.method
        })
        this.setState({ method: '' })
        this.getMethods();        
    }

    deleteMethod = async (id) => {
        await axios.delete(process.env.REACT_APP_BASE_URL + '/api/methods/' + id)
        this.getMethods(); 
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-4">
                    <div className="card card-body">
                        <h5>Registrar nuevo metodo</h5>
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    value={this.state.method}
                                    onChange={this.onChangeMethod}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Guardar
                            </button>
                        </form>
                    </div>
                </div>
                <div className="col-md-8">
                    <ul className="list-group">
                        {
                            this.state.methods.map(method => (
                                <li className="list-group-item list-group-item-action" 
                                    key={method._id}
                                    onDoubleClick={() => this.deleteMethod(method._id)}
                                >
                                    {method.method}
                                </li>)
                            )
                        }
                    </ul>
                </div>
            </div>
        )
    }
}
