import React, { Component } from 'react';

export default class Success_three extends Component {

    render() {
        return(
            <div className="row m-0 justify-content-center align-items-center vh-100">
                <div className="col-sm-4">
                    <div className="text-center text-success">
                        <h2><i className="fa fa-check-circle-o text-success mr-3" aria-hidden="true"></i>Formulario Registrado</h2>
                        <hr/>
                        <h5 className="text-muted">Nos contactaremos con usted via correo electrónico para indicarle la fecha en la que podrá pasar a recoger su tarjeta. Muchas Gracias.</h5>
                    </div>
                </div>
            </div>
        )
    }

}