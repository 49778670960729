import React, { useState, useContext } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import UserContext from '../context/UserContext';
import { Button } from 'devextreme-react/button';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import '../css/login.css';
import imagen from '../img/bg.jpg';
import go from '../img/go.png';

var show = '';

export default function Login() {
    
    const [code, setCode] = useState();
    const [password, setPassword] = useState();

    const { setUserData } = useContext(UserContext);
    const history = useHistory();

    const notify = (message, type) => {
        if (type === 'success') {
            toast.success(message, { position: toast.POSITION.TOP_RIGHT });
        } else if (type === 'error') {
            toast.error(message, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    const showExample = async e => {
        show = '';
    }

    const submit = async e => {
        
        const loginUser = { code, password };
        console.log(loginUser);
        // const res = await axios.post(process.env.REACT_APP_BASE_URL + '/api/plates/check', loginUser);
        // var placas = res.data;
        // //plates.forEach(element => console.log(element.Placa));
        // if(placas.length == 1){
        //     let placa = placas[0];
        //     if(placa.Placa.toUpperCase() == loginUser.plate.toUpperCase()){
        //         localStorage.setItem("placa", placa.Placa);
        //         localStorage.setItem("consorcio", placa.ID_Agenda);
        //         history.push('/create');
        //     } else{
        //         notify('Placa no registrada','error');
        //     }
        // } else{
        //     notify('Placa no registrada','error');
        // }
        await axios.post(process.env.REACT_APP_BASE_URL + '/api/plates/check', loginUser)
        .then( res => {
            var datos = res.data;
            if (res.status === 200) {
                if(datos.length == 1){
                    let dataUser = datos[0];
                    console.log(dataUser)
                    if(dataUser.Codigo.toUpperCase() == loginUser.code.toUpperCase()){
                        localStorage.setItem("placa", dataUser.Placa);
                        localStorage.setItem("codigo", dataUser.Codigo);
                        localStorage.setItem("id_placa", dataUser.ID_Placa);
                        localStorage.setItem("consorcio", dataUser.ID_Agenda);
                        localStorage.setItem("fecha_registro", dataUser.FechaHoraActualizacion);
                        if(dataUser.ID_Registro == 0 || dataUser.FechaHoraActualizacion === null){
                            history.push('/view');
                        } else{
                            history.push('/prices_list_consortium');
                        }
                        window.location.reload();
                    } else{
                        notify('Código ó Placa no registrada','error');
                    }
                } else{
                    notify('Código ó Placa no registrada','error');
                }
            } else if (res.status === 201) {
                notify(res.data.message,'error');
            }                
        })
        .catch( err => {
            if (err.response.status === 500) {
                notify(err.response.data.message,'error');
            }
        })

        // if(placa.Placa.toUpperCase() == loginUser.plate.toUpperCase()){
        //     localStorage.setItem("placa", placa.Placa);
        //     localStorage.setItem("consorcio", placa.ID_Agenda);
        //     history.push('/create');
        // } else{
        //     notify('Placa no registrada','error');
        // }

        // plates.forEach((data) => {
        //     if(data.Placa.toUpperCase() == loginUser.plate.toUpperCase()){
                
        //         //console.log(data.Placa);
                
        //         localStorage.setItem("placa", data.Placa);
        //         localStorage.setItem("consorcio", data.ID_Agenda);
        //         //console.log(data.ID_Agenda);
        //         history.push('/create');
        //     } 
        //     else{
        //         notify('Placa no registrada','error');
        //     }
        //   })

        //alert(res.data.placa);

        // plate.map(value => {
        //     alert(value.Placa);
        // })
        // await axios.post(process.env.REACT_APP_BASE_URL + '/api/users/prelogin', loginUser)
        //     .then( res => {
        //         if (res.status === 200) {
        //             setUserData({
        //                 token: res.data.token,
        //                 user: res.data.user
        //             });        
        //             localStorage.setItem('auth-token', res.data.token);
        //             alert(res.data.validacion);
        //             localStorage.setItem('usuario', res.data.validacion);
        //             history.push('/forms');
        //         } else if (res.status === 201) {
        //             notify(res.data.message,'error');
        //         }                
        //     })
        //     .catch( err => {
        //         if (err.response.status === 500) {
        //             notify(err.response.data.message,'error');
        //         }
        //     })        
    };

    return(
        <section className="ftco-section img js-fullheight t-letter" style={{ backgroundImage: `url(${imagen})` }}>
		<div className='div-go-logo'><img className='go-logo' src={go} alt=""/></div>
        <div className="container">
			<div className="row justify-content-center">
				<div className="col-md-6 text-center mb-5">
					<h2 className="heading-section"></h2>
				</div>
			</div>
			<div className="row justify-content-center">
				<div className="col-md-6 col-lg-4">
					<div className="login-wrap p-0">
		      	<form action="#" className="signin-form">
                    <div style={{textAlign: 'center'}} className="form-group">  
                        <span style={{display: show}} className='input-ejemplo t-letter'><b>Ingrese su número de placa o código:</b></span>
                    </div> 
		      		<div className="form-group">  
		      			<input type="text" className="form-control login-input" placeholder="Ejemplo: ABC-123 ó 4567-AB" 
                          name="placa"
                          autoComplete="nope"
                          onChange={ e => setCode(e.target.value) }
                          onFocus={showExample}
                          required/>
		      		</div>
	            <div className="form-group d-none">
	              <input id="password-field" type="password" className="form-control" placeholder="Password" required/>
	              <span toggle="#password-field" className="fa fa-fw fa-eye field-icon toggle-password"></span>
	            </div>
	            <div className="form-group" style={{textAlign: 'center'}}>
	            	<button 
                    type="submit" 
                    icon="fa fa-sign-out mx-2 text-light"
                    text="INGRESAR"
                    className="btn btn-primary btn-sm login-submit"
                    stylingMode="contained"
                    onClick={submit}><b>INGRESAR</b></button>
	            </div>
	          </form>
		      </div>
				</div>
			</div>
		</div>
        <ToastContainer/>
	</section>
    
    )
}