import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import UserContext from './context/UserContext';
import axios from 'axios';

import Navigation from './components/Navigation';
import Login from './components/Login';
import Prelogin from './components/Prelogin';
import FormsList from './components/FormsList';
import CreateForm from './components/CreateForm';
import ViewData from './components/ViewData';
import Bono from './components/Bono';
import CreateMethod from './components/CreateMethod';
import Success from './components/Success';
import Success_two from './components/Success_two';
import Success_three from './components/Success_three';
import EessList from './components/EessList';
import PricesList from './components/PricesList';
import DeliverCard from './components/DeliverCard';
import Success_four from './components/Success_four';

function App() {

  const [ userData, setUserData ] = useState({
    toke: undefined,
    user: undefined
  })

  useEffect(() => {
    const checkLoggedIn = async () => {
      let token = localStorage.getItem('auth-token');
      if (token === null) {
        localStorage.setItem('auth-token', '');
        token = "";
      }
      const tokenRes = await axios.post(
        process.env.REACT_APP_BASE_URL + '/api/users/tokenIsValid', 
        null,
        { headers: { 'x-auth-token': token } }
      );
      if (tokenRes.data) {
        const userRes = await axios.get(
          process.env.REACT_APP_BASE_URL + '/api/users/user',
          { headers: { 'x-auth-token': token } }
        );
        setUserData({
          token,
          user: userRes.data
        })
      }
    }
    checkLoggedIn();
  }, [])

  return (
    <Router>
      <UserContext.Provider value={{ userData, setUserData }}>
        <Navigation/>
        <Switch>
          <Route path="/" exact component={Prelogin}/>
          <Route path="/login" component={Login}/>
          <Route path="/forms" component={FormsList}/>
          <Route path="/edit/:id" component={CreateForm}/>
          <Route path="/create" component={CreateForm}/>
          <Route path="/view" component={ViewData}/>
          <Route path="/bono" component={Bono}/>
          <Route path="/method" component={CreateMethod}/>
          <Route path="/success" component={Success}/>
          <Route path="/success_two" component={Success_two}/>
          <Route path="/success_three" component={Success_three}/>
          <Route path="/eess_list_consortium" component={EessList}/>
          <Route path="/prices_list_consortium" component={PricesList}/>
          <Route path="/deliver_card" component={DeliverCard}/>
          <Route path="/success_four" component={Success_four}/>
        </Switch>         
      </UserContext.Provider>          
    </Router>   
  );
}

export default App;
