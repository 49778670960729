import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {Modal, ModalBody} from 'reactstrap';
//import logo_cabify from '../img/Cabify-logo-purple.png';
import logo_cabify from '../img/Cabify-Logo-Moradul-RGB.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let plate = ''
let phone = ''

export default class CreateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            methods: [],
            eesss: [],
            plates: [],
            name: '',
            document: '',
            email: '',
            plate: '',
            phone: '',
            method: '',
            eess: '',
            address: '',
            reference: '',
            responsable: '',
            statement: 'Pendiente',
            card: '',
            operations: '',
            condition: 0,
            isDisabled: false,
            textButton: 'SOLICITAR',
            abierto: false,
            d2: 'NO',
            g84: 'NO',
            g90: 'NO',
            g95: 'NO',
            g97: 'NO',
            g98: 'NO',
            glp: 'NO',
            gnv: 'NO',
            errors: {
                name: '',
                document: '',
                email: '',
                plate: '',
                phone: '',
                method: '',
                eess: '',
                address: '',
                reference: '',
                responsable: ''
            }
        }
    };

    notify = (message, type) => {
        if (type === 'success') {
            toast.success(message, { position: toast.POSITION.TOP_RIGHT });
        } else if (type === 'error') {
            toast.error(message, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    getMethods = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + '/api/methods');
        this.setState({ methods: res.data });
    };

    // 1 - Lo recogeré en alguna de las estaciones de Servicio parte del convenio
    // 2 - Lo recogeré en la oficina de Redcol (Chacarilla – Surco)

    /* getStations = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + '/api/eess');
        this.setState({ eesss: res.data });
    }; */

    getPlates = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + '/api/plates');
        this.setState({ plates: res.data });
    };

    componentDidMount = () => {
        //this.getMethods();
        //this.getStations();
        this.getPlates();
    };

    renderProducts = (name, checked) => {
        if (name === 'd2') {
            if (checked) {
                this.setState({ d2: 'SI'});
            }
        } else if (name === 'g84') {
            if (checked) {
                this.setState({ g84: 'SI'});
            }
        } else if (name === 'g90') {
            if (checked) {
                this.setState({ g90: 'SI'});
            }
        } else if (name === 'g95') {
            if (checked) {
                this.setState({ g95: 'SI'});
            }
        } else if (name === 'g97') {
            if (checked) {
                this.setState({ g97: 'SI'});
            }
        } else if (name === 'g98') {
            if (checked) {
                this.setState({ g98: 'SI'});
            }
        } else if (name === 'glp') {
            if (checked) {
                this.setState({ glp: 'SI'});
            }
        } else if (name === 'gnv') { 
            if (checked) {
                this.setState({ gnv: 'SI'});
            }
        }
    }

    /* renderOptions = val => {
        if (val === 'Lo recogeré en la oficina de Redcol (Chacarilla – Surco)') {
            this.setState({ 
                eess: '', 
                address: 'Calle Monte Rosa 171, Oficina 3A - 2 Santiago de Surco - Lima',
                reference: '',
                responsable: '',
                condition: 1
            })
        } else if (val === 'Lo recogeré en alguna de las estaciones de Servicio parte del convenio') {
            this.setState({  
                eess: '',
                address: '',
                reference: '',
                responsable: '',
                condition: 2,
            })
        } else if (val === 'Delivery de Tarjeta a tu domicilio') {
            this.setState({ 
                eess: '', 
                address: '',
                condition: 3
            })
        } else {
            this.setState({ 
                eess: '', 
                address: '',
                condition: 0
            })
        }
    } */

    printAddress = (val) => {
        if (val !== 'Seleccionar EESS') {
            const array = val.split(',');
            const eess = array[0];
            const address = array[1];
            this.setState({ eess: eess, address: address });
        } else {
            this.setState({ eess: '', address: '' });
        }        
    }

    handleChange = e => {
        const { name, value, maxLength } = e.target;
        let validate = true;
        /* if (name === 'method') {
            this.renderOptions(value)
        } else */ if (name === 'eess') {
            this.printAddress(value)
            validate = false;
        } else if (name === 'plate') {
            if (value.length === 3) {
                e.target.value = value + '-'
            } else if (value.length === maxLength || value.length === (maxLength - 1)) {
                plate = value
            } else if (value.length > maxLength) {
                e.target.value = plate
            }
        } else if (name === 'phone') {
            if (value.length === maxLength) {
                phone = value
            } else if (value.length > maxLength) {
                e.target.value = phone
            }
        } else if (value === 'product') {
            this.renderProducts(name, e.target.checked);
        }
        if (value !== 'product') {
            if (validate) {
                this.setState({ [name]: value });
            }  
        }        
    }

    validate = () => {
        const state = this.state;
        let errors = this.state.errors;
        let valid = true;
        if (state.name === '') { errors.name = 'Ingresar un nombre valido *'} else { errors.name = '' }
        if (state.document === '') { errors.document = 'Ingresar un documento valido *'} else { errors.document = '' }
        if (state.email === '') { 
            errors.email = 'Ingresar un correo valido *'
        } else { 
            if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(state.email)) {
                errors.email = ''
            } else {
                errors.email = 'Ingresar un correo valido *'
            }            
        }
        if (state.plate === '') { errors.plate = 'Ingresar una placa valida *'} else { errors.plate = '' }
        if (state.phone === '') { errors.phone = 'Ingresar un número valido *'} else { errors.phone = '' }
        /* if (state.method === 'Seleccionar' || state.method === '') { errors.method = 'Ingresar un metodo valido *'} else { errors.method = '' }
        if (state.condition === 2) {
            if (state.eess === 'Seleccionar EESS' || state.eess === '') { errors.eess = 'Ingresar una EESS valida *'} else { errors.eess = '' }
        } else if (state.condition === 3) {
            if (state.address === '' && state.reference !== '' && state.responsable !== '') { 
                errors.address = 'Ingresar una Direccion valida *'
                errors.reference = ''
                errors.responsable = ''
            } else if (state.address !== '' && state.reference === '' && state.responsable !== '') { 
                errors.address = ''
                errors.reference = 'Ingresar una Referencia valida *'
                errors.responsable = ''                
            } else if (state.address !== '' && state.reference !== '' && state.responsable === '') { 
                errors.address = ''
                errors.reference = ''
                errors.responsable = 'Ingresar una Nombre valido *'
            } else if (state.address === '' && state.reference === '' && state.responsable !== '') {
                errors.address = 'Ingresar una Direccion valida *'
                errors.reference = 'Ingresar una Referencia valida *'
                errors.responsable = ''
            } else if (state.address !== '' && state.reference === '' && state.responsable === '') {
                errors.address = ''
                errors.reference = 'Ingresar una Referencia valida *'
                errors.responsable = 'Ingresar una Nombre valido *'
            } else if (state.address === '' && state.reference !== '' && state.responsable === '') {
                errors.address = ''
                errors.reference = ''
                errors.responsable = 'Ingresar una Nombre valido *'
            } else if (state.address === '' && state.reference === '' && state.responsable === '') {
                errors.address = 'Ingresar una Direccion valida *'
                errors.reference = 'Ingresar una Referencia valida *'
                errors.responsable = 'Ingresar una Nombre valido *'
            } else { 
                errors.address = ''
                errors.reference = ''
                errors.responsable = ''
            }
        } */
        this.setState({ errors });
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        return valid;
    }

    validate_plate = () => {
        var array = [];
        var valid2 = false;
        this.state.plates.map(value => {
            if (value.Placa.toUpperCase() === this.state.plate.toUpperCase()) {
                //this.setState({ abierto: false });
                array.push(true)
            } else {
                //this.setState({ abierto: true });
                array.push(false)
            }
        })
        console.log(array)
        if (array.indexOf(true) != -1) {
            this.setState({ abierto: false });
            valid2 = true;
        } else {
            this.setState({ abierto: true });
            valid2 = false;
        }
        return valid2;
    }
    
    cerrar_modal = () => {
        this.setState({ isDisabled: false, textButton: 'SOLICITAR' });
        this.setState({ abierto: false });
    }

    sendEmail = async () => {
        const newForm = {
            name: this.state.name.toUpperCase(),
            document: this.state.document,
            email: this.state.email,
            plate: this.state.plate.toUpperCase(),
            phone: this.state.phone,
            method: this.state.method,
            eess: this.state.eess,
            address: this.state.address,
            statement: this.state.statement
        };
        await axios.post(process.env.REACT_APP_BASE_URL + '/api/emails', newForm);
        /* if (this.state.method === 'Lo recogeré en la oficina de Redcol (Chacarilla – Surco)') {
            window.location.href = "/success";
        } else if (this.state.method === 'Lo recogeré en alguna de las estaciones de Servicio parte del convenio') {
            window.location.href = "/success_two";
        } else if (this.state.method === 'Delivery de Tarjeta a tu domicilio') {
            window.location.href = "/success_three";
        }  */
        window.location.href = "/success";
    }

    onSubmit = async e => {
        e.preventDefault();
        this.setState({ isDisabled: true, textButton: 'ENVIANDO...' });
        if (this.validate()) {
            if (this.validate_plate()) {
                const newForm = {
                    name: this.state.name,
                    document: this.state.document,
                    email: this.state.email,
                    plate: this.state.plate.toUpperCase(),
                    phone: this.state.phone,
                    method: this.state.method,
                    eess: this.state.eess,
                    address: this.state.address, 
                    reference: this.state.reference,
                    responsable: this.state.responsable,
                    statement: this.state.statement,
                    card: this.state.card,
                    operations: this.state.operations,
                    d2: this.state.d2,
                    g84: this.state.g84,
                    g90: this.state.g90,
                    g95: this.state.g95,
                    g97: this.state.g97,
                    g98: this.state.g98,
                    glp: this.state.glp,
                    gnv: this.state.gnv
                };
                await axios.post(process.env.REACT_APP_BASE_URL + '/api/forms', newForm);
                this.sendEmail();
            }
        } else {
            this.setState({ isDisabled: false, textButton: 'SOLICITAR' });
            this.notify(' Campos incompletos o invalidos','error');
        }
    }

    render() {
        const { errors } = this.state;
        return (
            <div className="col-md-12">
                <form onSubmit={this.onSubmit}>
                    <div className="container p-2">
                        <div className="form-group logo">
                            <img src={logo_cabify} style={{width: 90, height:30}} alt=""/>
                        </div>
                        <div className="form-group">
                            <h4>Solicitud de Tarjeta</h4>
                        </div>
                        <br />
                        <div className="form-group">
                            <label>Nombres y Apellidos</label>
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                autoComplete="nope"
                                onChange={this.handleChange}
                            />
                            { errors.name.length > 0 && <span className='text-danger font-weight-bold'>{ errors.name } </span> }
                        </div>
                        <div className="form-group">
                            <label>Documento de  Identidad (DNI/CE/PTP)</label>
                            <input
                                type="text"
                                className="form-control"
                                name="document"
                                autoComplete="nope"
                                onChange={this.handleChange}
                            />
                            { errors.document.length > 0 && <span className='text-danger font-weight-bold'>{ errors.document } </span> }
                        </div>
                        <div className="form-group">
                            <label>Correo Electrónico</label>
                            <input
                                type="text"
                                className="form-control"
                                name="email"
                                autoComplete="nope"
                                onChange={this.handleChange}
                            />
                            { errors.email.length > 0 && <span className='text-danger font-weight-bold'>{ errors.email } </span> }
                        </div>
                        <div className="form-group row">
                            <div className="col">
                                <label>Placa</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="plate"
                                    maxLength="7"
                                    autoComplete="nope"
                                    onChange={this.handleChange}
                                />
                                { errors.plate.length > 0 && <span className='text-danger font-weight-bold'>{ errors.plate } </span> }
                            </div>
                            <div className="col">
                                <label>Teléfono</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="phone"
                                    maxLength="9"
                                    autoComplete="nope"
                                    onChange={this.handleChange}
                                />
                                { errors.phone.length > 0 && <span className='text-danger font-weight-bold'>{ errors.phone } </span> }
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Marcar el tipo de combustible de tu vehiculo</label>
                            <div className="contenedor-productos row-cols-4">
                                <div className="producto col">
                                    <label>D2</label>
                                    <input 
                                        type="checkbox" 
                                        name="d2"
                                        value="product"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="producto col">
                                    <label>G84</label>
                                    <input 
                                        type="checkbox" 
                                        name="g84"
                                        value="product"
                                        onChange={this.handleChange} 
                                    />
                                </div>
                                <div className="producto col">
                                    <label>G90</label>
                                    <input 
                                        type="checkbox" 
                                        name="g90"
                                        value="product"
                                        onChange={this.handleChange} 
                                    />
                                </div>
                                <div className="producto col">
                                    <label>G95</label>
                                    <input 
                                        type="checkbox" 
                                        name="g95"
                                        value="product"
                                        onChange={this.handleChange} 
                                    />
                                </div>
                                <div className="producto col">
                                    <label>G97</label>
                                    <input 
                                        type="checkbox" 
                                        name="g97"
                                        value="product"
                                        onChange={this.handleChange} 
                                    />
                                </div>
                                <div className="producto col">
                                    <label>G98</label>
                                    <input 
                                        type="checkbox" 
                                        name="g98"
                                        value="product"
                                        onChange={this.handleChange} 
                                    />
                                </div>
                                <div className="producto col">
                                    <label>GLP</label>
                                    <input 
                                        type="checkbox" 
                                        name="glp"
                                        value="product"
                                        onChange={this.handleChange} 
                                    />
                                </div>
                                <div className="producto col">
                                    <label>GNV</label>
                                    <input 
                                        type="checkbox" 
                                        name="gnv"
                                        value="product"
                                        onChange={this.handleChange} 
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Select method */}
                        {/* <div className="form-group">
                            <label>¿Cómo quieres recibir tu tarjeta?</label>
                            <select
                                className="form-control"
                                name="method"
                                onChange={this.handleChange}
                            >
                                <option>Seleccionar</option>
                                {
                                    this.state.methods.map(method =>
                                        <option
                                            key={method._id}
                                        >
                                            {method.method}
                                        </option>)
                                }
                            </select>
                            { errors.method.length > 0 && <span className='text-danger font-weight-bold'>{ errors.method } </span> }
                        </div>
                        {
                            this.state.condition === 1 ? 
                                (
                                    <Fragment>
                                        <div className="form-group">
                                            <label>Dirección de Recojo</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="address"
                                                autoComplete="nope"
                                                onChange={this.handleChange}
                                                value={this.state.address}
                                                disabled={true}
                                            />
                                        </div>
                                    </Fragment>                                   
                                ) : 
                            this.state.condition === 3 ? 
                                (
                                    <Fragment>
                                        <div className="form-group">
                                            <label>Dirección de Entrega</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="address"
                                                autoComplete="nope"
                                                onChange={this.handleChange}
                                            />
                                            { errors.address.length > 0 && <span className='text-danger font-weight-bold'>{ errors.address } </span> }
                                        </div>
                                        <div className="form-group">
                                            <label>Referencia</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="reference"
                                                autoComplete="nope"
                                                onChange={this.handleChange}
                                            />
                                            { errors.reference.length > 0 && <span className='text-danger font-weight-bold'>{ errors.reference } </span> }
                                        </div>
                                        <div className="form-group">
                                            <label>¿Quien recibira la tarjeta?</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="responsable"
                                                autoComplete="nope"
                                                onChange={this.handleChange}
                                            />
                                            { errors.responsable.length > 0 && <span className='text-danger font-weight-bold'>{ errors.responsable } </span> }
                                        </div>
                                    </Fragment>                                    
                                ) 
                                :
                            this.state.condition === 2 ? 
                                (
                                    <Fragment>
                                        <div className="form-group">
                                            <label>EESS</label>
                                            <select
                                                className="form-control"
                                                name="eess"
                                                onChange={this.handleChange}
                                            >
                                                <option>Seleccionar EESS</option>
                                                {
                                                    this.state.eesss.map(eess =>
                                                        <option
                                                            key={eess.id_estacion} value={[eess.Nombre, eess.Dirección]}
                                                        >
                                                            {eess.Nombre}
                                                        </option>)
                                                }
                                            </select>
                                            { errors.eess.length > 0 && <span className='text-danger font-weight-bold'>{ errors.eess } </span> }
                                        </div>
                                        <div className="form-group">
                                            <label>Dirección de EESS</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="address"
                                                autoComplete="nope"
                                                value={this.state.address}
                                                disabled={true}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </Fragment>                                
                                ) : ''
                        } */}
                        <button 
                            type="submit" 
                            className="btn btn-primary float-right"
                            disabled={this.state.isDisabled}
                        >
                            <i className="fa fa-credit-card mx-2" aria-hidden="true"></i> { this.state.textButton }
                        </button>                   
                    </div>
                </form>
                <ToastContainer/>
                <Modal isOpen={this.state.abierto} style={{ position: 'absolute', top: '50%', left: '48%', transform: 'translate( -50%, -50%)', width: '90%' }}>
                    <ModalBody className='text-danger font-weight-bold'>
                        <i className="fa fa-times-circle-o text-danger mb-3" aria-hidden="true"></i>
                        <h5 style={{ textAlign: 'center', color: 'black' }}>La placa ingresada no se encuentra autorizada para realizar la solicitud.</h5>
                        <button onClick={this.cerrar_modal} className="btn btn-danger mt-3">Cerrar</button>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}
