import React, { useState, useContext } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import UserContext from '../context/UserContext';
import { Button } from 'devextreme-react/button';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

export default function Login() {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const { setUserData } = useContext(UserContext);
    const history = useHistory();

    const notify = (message, type) => {
        if (type === 'success') {
            toast.success(message, { position: toast.POSITION.TOP_RIGHT });
        } else if (type === 'error') {
            toast.error(message, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    const submit = async e => {
        const loginUser = { username, password };
        await axios.post(process.env.REACT_APP_BASE_URL + '/api/users/login', loginUser)
            .then( res => {
                if (res.status === 200) {
                    setUserData({
                        token: res.data.token,
                        user: res.data.user
                    });        
                    localStorage.setItem('auth-token', res.data.token);
                    history.push('/forms');
                } else if (res.status === 201) {
                    notify(res.data.message,'error');
                }                
            })
            .catch( err => {
                if (err.response.status === 500) {
                    notify(err.response.data.message,'error');
                }
            })        
    };

    return(
        <div className="col-md-4 offset-md-4">
            <div className="container p-2">
                <div className="form-group">
                    <h4>Login</h4>
                </div>
                <br />
                <div className="form-group">
                    <label>Usuario</label>
                    <input
                        type="text"
                        className="form-control"
                        name="username"
                        autoComplete="nope"
                        onChange={ e => setUsername(e.target.value) }
                    />
                </div>
                <div className="form-group">
                    <label>Contraseña</label>
                    <input
                        type="password"
                        className="form-control"
                        name="password"
                        autoComplete="nope"
                        onChange={ e => setPassword(e.target.value) }
                    />
                </div>
                <div className="form-group float-right">
                    <Button
                        width={'auto'}
                        icon="fa fa-sign-out mx-2 text-light"
                        text="INGRESAR"
                        className="btn btn-primary btn-sm"
                        stylingMode="contained"
                        onClick={submit}
                    />  
                </div>                                
            </div>
            <ToastContainer/>
        </div>
    )
}