import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {Modal, ModalBody} from 'reactstrap';
//import logo_cabify2 from '../img/Cabify-logo-purple.png';
//import logo_chazki from '../img/logo-chazki-azul.png';
import logo_chazki from '../img/chk.png';
import logo_pedidos_ya from '../img/pya.png';
import logo_uber from '../img/uber.png';
//import logo_pedidos_ya from '../img/logo_pedidosya.png';
//import logo_cabify from '../img/Cabify-Logo-Moradul-RGB.png';
import logo_cabify from '../img/cbf.png';
import logo_null from '../img/null.png';
import logo_jtours from '../img/j&jtours3.png';
import logo_jtours2 from '../img/j&jtours2.png';
import logo_redcol from '../img/redcol.png';
import logo_redcol2 from '../img/redcol2.png';
import logo_3a from '../img/3a.png';
import logo_3a2 from '../img/3a2.png';
import logo_tmonterrico from '../img/tmonterrico.png';
import logo_rapired from '../img/rapired.png';
import logo_rapired2 from '../img/rapired2.png';
import logo_mimotorizado from '../img/mimotorizado.png';
import logo_mimotorizado2 from '../img/mimotorizado2.png';
import logo_motobros from '../img/motobros.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let plate = ''
let phone = ''

// var placa = localStorage.getItem("placa");
// var id_placa = localStorage.getItem("id_placa");
// var registro = localStorage.getItem("registro");
// var nombre = localStorage.getItem("nombre");
// var correo = localStorage.getItem("correo");
// var telefono = localStorage.getItem("telefono");

export default class CreateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            methods: [],
            eesss: [],
            plates: [],
            name: '',
            document: '',
            email: '',
            plate: '',
            id_plate: '',
            registro: '',
            phone: '',
            fecha_registro: '',
            method: '',
            eess: '',
            address: '',
            reference: '',
            responsable: '',
            statement: 'Pendiente',
            card: '',
            operations: '',
            condition: 0,
            isDisabled: false,
            textButton: 'GUARDAR',
            abierto: false,
            d2: 'NO',
            g84: 'NO',
            g90: 'NO',
            g95: 'NO',
            g97: 'NO',
            g98: 'NO',
            gr: 'NO',
            gp: 'NO',
            glp: 'NO',
            gnv: 'NO',
            errors: {
                name: '',
                document: '',
                email: '',
                plate: '',
                phone: '',
                method: '',
                eess: '',
                address: '',
                reference: '',
                responsable: '',
                fuel: ''
            }
        }
    };

    notify = (message, type) => {
        if (type === 'success') {
            toast.success(message, { position: toast.POSITION.TOP_RIGHT });
        } else if (type === 'error') {
            toast.error(message, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    getMethods = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + '/api/methods');
        this.setState({ methods: res.data });
    };

    // 1 - Lo recogeré en alguna de las estaciones de Servicio parte del convenio
    // 2 - Lo recogeré en la oficina de Redcol (Chacarilla – Surco)

    /* getStations = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + '/api/eess');
        this.setState({ eesss: res.data });
    }; */

    getPlates = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + '/api/plates');
        this.setState({ plates: res.data });
    };

    logout = async () => {
        // setUserData({
        //     token: undefined,
        //     user: undefined
        // });
        window.location.href = "./";
        localStorage.setItem('auth-token', '');
        localStorage.setItem("placa", '');
        localStorage.setItem("codigo", '');
        localStorage.setItem("id_placa", '');
        //localStorage.setItem("consorcio", '');
        // window.location.href = "./";
    }

    menu = async (e) => {
        //var fecha_registro = localStorage.getItem("fecha_registro");
        e.preventDefault();
        if(this.state.fecha_registro === null){
            document.getElementById('menu-close').click()
            this.setState({ abierto: true });
        }   
    }

    getData = async () => {
        let code = localStorage.getItem("codigo");
        if(code == '' || code == null){
            window.location.href = "./";
        } else{
            let password = "123";
            //console.log(plate)
            const loginUser = { code , password};
            const res = await axios.post(process.env.REACT_APP_BASE_URL + '/api/plates/check', loginUser)
            let dato = res.data[0];
            this.setState({ name: dato.Nombre });
            this.setState({ phone: dato.Telefono });
            this.setState({ email: dato.Correo });
            this.setState({ registro: dato.ID_Registro });
            this.setState({ plate: dato.Placa });
            this.setState({ id_plate: dato.ID_Placa });
            this.setState({ fecha_registro: dato.FechaHoraActualizacion });
            this.setState({ d2: dato.D2 });
            this.setState({ g84: dato.G84 });
            this.setState({ g90: dato.G90 });
            this.setState({ g95: dato.G95 });
            this.setState({ g97: dato.G97 });
            this.setState({ g98: dato.G98 });
            this.setState({ gr: dato.GR });
            this.setState({ gp: dato.GP });
            this.setState({ glp: dato.GLP });
            this.setState({ gnv: dato.GNV });
            localStorage.setItem("fecha_registro", dato.FechaHoraActualizacion);
        }
    };

    componentDidMount = () => {
        //this.getMethods();
        //this.getStations();
        //this.getPlates();
        this.getData();
    };

    renderProducts = (name, checked) => {
        if (name === 'd2') {
            if (checked) {
                this.setState({ d2: 'SI'});
            } else{
                this.setState({ d2: 'NO'});
            }
        } else if (name === 'g84') {
            if (checked) {
                this.setState({ g84: 'SI'});
            } else{
                this.setState({ g84: 'NO'});
            }
        } else if (name === 'g90') {
            if (checked) {
                this.setState({ g90: 'SI'});
            } else{
                this.setState({ g90: 'NO'});
            }
        } else if (name === 'g95') {
            if (checked) {
                this.setState({ g95: 'SI'});
            } else{
                this.setState({ g95: 'NO'});
            }
        } else if (name === 'g97') {
            if (checked) {
                this.setState({ g97: 'SI'});
            } else{
                this.setState({ g97: 'NO'});
            }
        } else if (name === 'g98') {
            if (checked) {
                this.setState({ g98: 'SI'});
            } else{
                this.setState({ g98: 'NO'});
            }
        } else if (name === 'gr') {
            if (checked) {
                this.setState({ gr: 'SI'});
            } else{
                this.setState({ gr: 'NO'});
            }
        } else if (name === 'gp') {
            if (checked) {
                this.setState({ gp: 'SI'});
            } else{
                this.setState({ gp: 'NO'});
            }
        } else if (name === 'glp') {
            if (checked) {
                this.setState({ glp: 'SI'});
            } else{
                this.setState({ glp: 'NO'});
            }
        } else if (name === 'gnv') { 
            if (checked) {
                this.setState({ gnv: 'SI'});
            } else{
                this.setState({ gnv: 'NO'});
            }
        }
    }

    /* renderOptions = val => {
        if (val === 'Lo recogeré en la oficina de Redcol (Chacarilla – Surco)') {
            this.setState({ 
                eess: '', 
                address: 'Calle Monte Rosa 171, Oficina 3A - 2 Santiago de Surco - Lima',
                reference: '',
                responsable: '',
                condition: 1
            })
        } else if (val === 'Lo recogeré en alguna de las estaciones de Servicio parte del convenio') {
            this.setState({  
                eess: '',
                address: '',
                reference: '',
                responsable: '',
                condition: 2,
            })
        } else if (val === 'Delivery de Tarjeta a tu domicilio') {
            this.setState({ 
                eess: '', 
                address: '',
                condition: 3
            })
        } else {
            this.setState({ 
                eess: '', 
                address: '',
                condition: 0
            })
        }
    } */

    printAddress = (val) => {
        if (val !== 'Seleccionar EESS') {
            const array = val.split(',');
            const eess = array[0];
            const address = array[1];
            this.setState({ eess: eess, address: address });
        } else {
            this.setState({ eess: '', address: '' });
        }        
    }

    handleChange = e => {
        const { name, value, maxLength } = e.target;
        let validate = true;
        /*if (name === 'name') {
            this.setState({ name: value.toUpperCase() });
            //alert(value.toUpperCase());
        } else */ if (name === 'eess') {
            this.printAddress(value)
            validate = false;
        } else if (name === 'plate') {
            // if (value.length === 3) {
            //     e.target.value = value + '-'
            // } else if (value.length === maxLength || value.length === (maxLength - 1)) {
            //     plate = value
            // } else if (value.length > maxLength) {
            //     e.target.value = plate
            // }
        } else if (name === 'phone') {
            if (value.length === maxLength) {
                phone = value
            } else if (value.length > maxLength) {
                e.target.value = phone
            }
        } else if (value === 'product') {
            this.renderProducts(name, e.target.checked);
        } 
        // else if (name === 'name') {
        //     this.state.name = value.toUpperCase();
        // }
        if (value !== 'product') {
            if (validate) {
                this.setState({ [name]: value });
            }  
        }        
    }

    validate = async() => {
        const state = this.state;
        let errors = this.state.errors;
        let valid = true;
        if (state.name === '') { errors.name = 'Ingresar un nombre valido *'} else { errors.name = '' }
        // if (state.document === '') { errors.document = 'Ingresar un documento valido *'} else { errors.document = '' }
        if (state.email == null) { state.email = '';}
        if (state.email === '') { 
            errors.email = 'Ingresar un correo valido *'
        } else { 
            if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(state.email)) {
                errors.email = ''
            } else {
                errors.email = 'Ingresar un correo valido *';
            }            
        }
        if (state.plate === '') { 
            errors.plate = 'Ingresar una placa valida *'
        } else { 
            var regex = /^[^-]*-[^-]*$/;
            if (regex.test(state.plate) && state.plate.length >=7 && state.plate.length <=8) {
                const dataForm = {
                    plate: state.plate,
                    password: '123'
                }
                await axios.post(process.env.REACT_APP_BASE_URL + '/api/plates/checkPlate', dataForm)
                .then( res => {
                    let id_placa = localStorage.getItem("id_placa");
                    let found = res.data.some(value => value.ID_Placa != id_placa);

                    if (found) {
                        errors.plate = 'Placa ya registrada *';
                    } else {
                        errors.plate = '';
                    }             
                })
                .catch( err => {
                    if (err.response.status === 500) {
                        console.log(err);
                    }
                })
              } else {
                errors.plate = 'Ingresar una placa valida *' // La cadena no contiene un guion o contiene más de uno.
              }
        }
        //console.log(state.phone);
        if (state.phone == '' || state.phone == null) { errors.phone = 'Número no valido *' } 
        else if(state.phone.length != 9) {errors.phone = 'Número no valido *' }
        else { errors.phone = '' }
        
        
        if(state.d2 != 'NO' || state.gr != 'NO' || state.gp != 'NO' || state.glp != 'NO'){
        //if(state.d2 != 'NO' || state.g84 != 'NO' || state.g90 != 'NO' || state.g95 != 'NO' || state.g97 != 'NO' || state.g98 != 'NO' || state.gr != 'NO' || state.gp != 'NO' || state.glp != 'NO' || state.gnv != 'NO'){
            errors.fuel = '';
        } else { errors.fuel = 'Seleccionar un tipo de combustible*'; }
        /* if (state.method === 'Seleccionar' || state.method === '') { errors.method = 'Ingresar un metodo valido *'} else { errors.method = '' }
        if (state.condition === 2) {
            if (state.eess === 'Seleccionar EESS' || state.eess === '') { errors.eess = 'Ingresar una EESS valida *'} else { errors.eess = '' }
        } else if (state.condition === 3) {
            if (state.address === '' && state.reference !== '' && state.responsable !== '') { 
                errors.address = 'Ingresar una Direccion valida *'
                errors.reference = ''
                errors.responsable = ''
            } else if (state.address !== '' && state.reference === '' && state.responsable !== '') { 
                errors.address = ''
                errors.reference = 'Ingresar una Referencia valida *'
                errors.responsable = ''                
            } else if (state.address !== '' && state.reference !== '' && state.responsable === '') { 
                errors.address = ''
                errors.reference = ''
                errors.responsable = 'Ingresar una Nombre valido *'
            } else if (state.address === '' && state.reference === '' && state.responsable !== '') {
                errors.address = 'Ingresar una Direccion valida *'
                errors.reference = 'Ingresar una Referencia valida *'
                errors.responsable = ''
            } else if (state.address !== '' && state.reference === '' && state.responsable === '') {
                errors.address = ''
                errors.reference = 'Ingresar una Referencia valida *'
                errors.responsable = 'Ingresar una Nombre valido *'
            } else if (state.address === '' && state.reference !== '' && state.responsable === '') {
                errors.address = ''
                errors.reference = ''
                errors.responsable = 'Ingresar una Nombre valido *'
            } else if (state.address === '' && state.reference === '' && state.responsable === '') {
                errors.address = 'Ingresar una Direccion valida *'
                errors.reference = 'Ingresar una Referencia valida *'
                errors.responsable = 'Ingresar una Nombre valido *'
            } else { 
                errors.address = ''
                errors.reference = ''
                errors.responsable = ''
            }
        } */
        this.setState({ errors });
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        return valid;
    }

    validate_plate = () => {
        var array = [];
        var valid2 = false;
        this.state.plates.map(value => {
            if (value.Placa.toUpperCase() === this.state.plate.toUpperCase()) {
                //this.setState({ abierto: false });
                array.push(true)
            } else {
                //this.setState({ abierto: true });
                array.push(false)
            }
        })
        //console.log(array)
        if (array.indexOf(true) != -1) {
            this.setState({ abierto: false });
            valid2 = true;
        } else {
            this.setState({ abierto: true });
            valid2 = false;
        }
        return valid2;
    }
    
    cerrar_modal = () => {
        //this.setState({ isDisabled: false, textButton: 'SOLICITAR' });
        this.setState({ abierto: false });
    }

    // sendEmail = async () => {
    //     const newForm = {
    //         name: this.state.name.toUpperCase(),
    //         document: this.state.document,
    //         email: this.state.email,
    //         plate: this.state.plate.toUpperCase(),
    //         phone: this.state.phone,
    //         method: this.state.method,
    //         eess: this.state.eess,
    //         address: this.state.address,
    //         statement: this.state.statement
    //     };
    //     await axios.post(process.env.REACT_APP_BASE_URL + '/api/emails', newForm);
    //     /* if (this.state.method === 'Lo recogeré en la oficina de Redcol (Chacarilla – Surco)') {
    //         window.location.href = "/success";
    //     } else if (this.state.method === 'Lo recogeré en alguna de las estaciones de Servicio parte del convenio') {
    //         window.location.href = "/success_two";
    //     } else if (this.state.method === 'Delivery de Tarjeta a tu domicilio') {
    //         window.location.href = "/success_three";
    //     }  */
    //     window.location.href = "/success";
    // }

    // onSubmit = async e => {
    //     e.preventDefault();
    //     this.setState({ isDisabled: true, textButton: 'ENVIANDO...' });
    //     if (this.validate()) {
    //         if (this.validate_plate()) {
    //             const newForm = {
    //                 name: this.state.name,
    //                 document: this.state.document,
    //                 email: this.state.email,
    //                 plate: this.state.plate.toUpperCase(),
    //                 phone: this.state.phone,
    //                 method: this.state.method,
    //                 eess: this.state.eess,
    //                 address: this.state.address, 
    //                 reference: this.state.reference,
    //                 responsable: this.state.responsable,
    //                 statement: this.state.statement,
    //                 card: this.state.card,
    //                 operations: this.state.operations,
    //                 d2: this.state.d2,
    //                 g84: this.state.g84,
    //                 g90: this.state.g90,
    //                 g95: this.state.g95,
    //                 g97: this.state.g97,
    //                 g98: this.state.g98,
    //                 glp: this.state.glp,
    //                 gnv: this.state.gnv
    //             };
    //             await axios.post(process.env.REACT_APP_BASE_URL + '/api/forms', newForm);
    //             this.sendEmail();
    //         }
    //     } else {
    //         this.setState({ isDisabled: false, textButton: 'SOLICITAR' });
    //         this.notify(' Campos incompletos o invalidos','error');
    //     }
    // }

    onSubmit = async e => {
        e.preventDefault();
        let allValidate = await this.validate();
        if (allValidate) 
        {
            this.setState({ isDisabled: true, textButton: 'GUARDANDO...' });
            const newForm = {
                name: this.state.name.toUpperCase(),
                //document: this.state.document,
                email: this.state.email,
                phone: this.state.phone,
                registro: this.state.registro,
                id_placa: this.state.id_plate,
                plate: this.state.plate.toUpperCase(),
                d2: this.state.d2,
                g84: this.state.g84,
                g90: this.state.g90,
                g95: this.state.g95,
                g97: this.state.g97,
                g98: this.state.g98,
                gr: this.state.gr,
                gp: this.state.gp,
                glp: this.state.glp,
                gnv: this.state.gnv
                //method: this.state.method,
                //eess: this.state.eess,
                //address: this.state.address, 
                // reference: this.state.reference,
                // responsable: this.state.responsable,
                // statement: this.state.statement,
                // card: this.state.card,
                // operations: this.state.operations,
                // d2: this.state.d2,
                // g84: this.state.g84,
                // g90: this.state.g90,
                // g95: this.state.g95,
                // g97: this.state.g97,
                // g98: this.state.g98,
                // glp: this.state.glp,
                // gnv: this.state.gnv
            };
            console.log(newForm);
            await axios.post(process.env.REACT_APP_BASE_URL + '/api/plates/update', newForm)
            .then( res => {
                console.log(res);
                // var datos = res.data;
                // console.log(datos);
                this.setState({ isDisabled: false, textButton: 'GUARDAR' });
                localStorage.setItem("placa", this.state.plate);
                this.getData(); 
                window.location.href = "./prices_list_consortium";              
            })
            .catch( err => {
                if (err.response.status === 500) {
                    //notify(err.response.data.message,'error');
                }
            })
        }
    }

    render() {
        const { errors,name } = this.state;
        var placa = localStorage.getItem("placa");
        var consorcio = localStorage.getItem("consorcio");
        var logo;
        var logo2;
        var color;
        var color2;
        var colorB;
        var ancho;
        var largo;
        //var show_gnv = 'producto col d-none';
        var item = 'd-none';
        var menu_alt = 80;
        var link = process.env.REACT_APP_BASE_URL;
        if(consorcio == '20548704261'){
            logo = logo_cabify;
            logo2 = logo_cabify;
            color = '#7145d6';
            color2 = '#7145d6';
            colorB = '#7145d6';
            item = '';
            ancho = 100;
            largo = 30;
            //show_gnv = 'producto col';
        } else if(consorcio == '20485423444'){
            logo = logo_chazki;
            logo2 = logo_chazki;
            color = '#2778d4';
            color2 = '#2778d4';
            colorB = '#2778d4';
            ancho = 110;
            largo = 30;
        } else if(consorcio == '20556082708'){
            logo = logo_pedidos_ya;
            logo2 = logo_pedidos_ya;
            color = '#e30613';
            color2 = '#e30613';
            colorB = '#e30613';
            ancho = 140;
            largo = 30;
        } else if(consorcio == '20556126764'){
            logo = logo_uber;
            logo2 = logo_uber;
            color = '#000000';
            color2 = '#000000';
            colorB = '#000000';
            ancho = 140;
            largo = 30;
        } else if(consorcio == '20502926731'){
            logo = logo_tmonterrico;
            logo2 = logo_tmonterrico;
            color = '#000000';
            color2 = '#000000';
            colorB = '#000000';
            ancho = 140;
            largo = 30;
            menu_alt = 45;
        } else if(consorcio == '20492680924'){
            logo = logo_rapired;
            logo2 = logo_rapired2;
            color = '#144224';
            color2 = '#144224';
            colorB = '#144224';
            ancho = 140;
            largo = 30;
            menu_alt = 150;
        } else if(consorcio == '20505880171'){
            logo = logo_redcol;
            logo2 = logo_redcol2;
            // color = '#01366d';
            // color2 = '#d4ac44';
            // colorB = '#FFFFFF';
            color = '#EBE9E9';
            color2 = '#d4ac44';
            colorB = '#EBE9E9';
            
            ancho = 140;
            largo = 30;
        } else if(consorcio == '20602110673'){
            logo = logo_mimotorizado;
            logo2 = logo_mimotorizado2;
            // color = '#01366d';
            // color2 = '#d4ac44';
            // colorB = '#FFFFFF';
            color = '#e30613';
            color2 = '#e30613';
            colorB = '#e30613';
            
            ancho = 140;
            largo = 35;
            menu_alt = 180;
        } else if(consorcio == '20550876796'){
            logo = logo_jtours;
            logo2 = logo_jtours2;
            color = '#848cbc';
            color2 = '#848cbc';
            colorB = '#848cbc';
            //color = '#000000';
            ancho = 140;
            largo = 30;
            menu_alt = 45;
        } else if(consorcio == '20512829652'){
            logo = logo_3a;
            logo2 = logo_3a2;
            color = '#EBE9E9';
            color2 = '#ff040c';
            colorB = '#EBE9E9';
            ancho = 140;
            largo = 30;
            menu_alt = 150;
        } else if(consorcio == '20610741089'){
            logo = logo_motobros;
            logo2 = logo_motobros;
            color = '#000000';
            color2 = '#000000';
            colorB = '#000000';
            ancho = 110;
            largo = 30;
        } else{
            logo = logo_null;
            logo2 = logo_null;
            color = '#000000';
            color2 = '#000000';
            colorB = '#000000';
            ancho = 70;
            largo = 30;
        }
        return (
            <div className="col-md-12">
                <nav id="colorlib-main-nav" role="navigation">
                <a id="menu-close" className="js-colorlib-nav-toggle colorlib-nav-toggle active"><i></i></a>
                <div className="js-fullheight colorlib-table">
                    <div className="img" ></div>
                    <div className="colorlib-table-cell js-fullheight">
                    <div className="row no-gutters">
                        <div className="col-md-12">
                        <h1 className="mb-4"><a href="" className="logo"><img src={logo2} style={{width: 'auto', height: menu_alt}} alt=""/></a></h1>
                        <ul className='t-letter'>
                            <li className="active"><a href="./view"><span>Mi Perfil</span></a></li>
                            <li><a href="./prices_list_consortium"><span>Precios</span></a></li>
                            <li><a href="./eess_list_consortium"><span>EESS</span></a></li>
                            {/* <li className={item}><a href="./bono"><span>Bono</span></a></li> */}
                            <li><a href="" onClick={this.logout}><span>Salir</span></a></li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                </nav>
                <div id="colorlib-page">
                <header style={{backgroundColor: color}}>
                    <div className="container">
                        <div className="colorlib-navbar-brand">
                        <a style={{borderRadius: '10px', backgroundColor: colorB}} className="colorlib-logo" href="">
                            <img src={logo} style={{width: 'auto', height: largo}} alt=""/>
                        </a>
                        </div>
                        <a href="" className="js-colorlib-nav-toggle colorlib-nav-toggle" onClick={this.menu}><i></i></a>
                    </div>
                </header>

                <section className="hero-wrap js-fullheight t-letter">
                    <div className="container px-0">
                        <div className="row no-gutters slider-text js-fullheight align-items-top justify-content-center" data-scrollax-parent="true">
                        <div className="col-md-12 ftco-animate">
                            <div className="desc">
                            <form onSubmit={this.onSubmit}>
                    <div className="container bloque">
                        <div className="form-group text-center mt-2">
                            <h4 className='t-letter'><b>Mi Perfil</b></h4>
                        </div>
                        <br />
                        <div className="form-group mt-0">
                            <label><b>Nombres y Apellidos:</b></label>
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                autoComplete="nope"
                                style={{textTransform: 'uppercase'}}
                                value={ this.state.name }
                                onChange={this.handleChange}
                            />
                            { errors.name.length > 0 && <span className='text-danger font-weight-bold'>{ errors.name } </span> }
                        </div>
                        <div className="form-group d-none">
                            <label><b>Documento de  Identidad (DNI/CE/PTP)</b></label>
                            <input
                                type="text"
                                className="form-control"
                                name="document"
                                autoComplete="nope"
                                value={this.state.numero}
                                onChange={this.handleChange}
                            />
                            { errors.document.length > 0 && <span className='text-danger font-weight-bold'>{ errors.document } </span> }
                        </div>
                        <div className="form-group">
                            <label><b>Correo Electrónico</b></label>
                            <input
                                type="text"
                                className="form-control"
                                name="email"
                                autoComplete="nope"
                                value={ this.state.email }
                                onChange={this.handleChange}
                            />
                            { errors.email.length > 0 && <span className='text-danger font-weight-bold'>{ errors.email } </span> }
                        </div>
                        <div className="form-group row">
                            <div className="col">
                                <label><b>Placa</b></label>
                                <input
                                    type="text"
                                    className="form-control no-readonly-style"
                                    name="plate"
                                    maxLength="8"
                                    autoComplete="nope"
                                    //value={placa}
                                    //value={this.state.plate}
                                    value={(consorcio == '20556126764') ? this.state.plate : placa}
                                    onChange={this.handleChange}
                                    readOnly={consorcio != '20556126764'}
                                />
                                { errors.plate.length > 0 && <span className='text-danger font-weight-bold'>{ errors.plate } </span> }
                            </div>
                            <div className="col">
                                <label><b>Celular</b></label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="phone"
                                    maxLength="9"
                                    autoComplete="nope"
                                    value={ this.state.phone }
                                    onChange={this.handleChange}
                                />
                                { errors.phone.length > 0 && <span className='text-danger font-weight-bold'>{ errors.phone } </span> }
                            </div>
                        </div>
                        <div className="form-group">
                            <label><b>Marcar el tipo de combustible de tu vehiculo:</b></label>
                            <div className="contenedor-productos row-cols-4">
                                <div className="producto col">
                                    <label>D2</label>
                                    <input 
                                        type="checkbox" 
                                        name="d2"
                                        value="product"
                                        checked={ this.state.d2 == 'SI' ? 'checked' : '' }
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="producto col d-none">
                                    <label>G84</label>
                                    <input 
                                        type="checkbox" 
                                        name="g84"
                                        value="product"
                                        checked={ this.state.g84 == 'SI' ? 'checked' : '' }
                                        onChange={this.handleChange} 
                                    />
                                </div>
                                <div className="producto col d-none">
                                    <label>G90</label>
                                    <input 
                                        type="checkbox" 
                                        name="g90"
                                        value="product"
                                        checked={ this.state.g90 == 'SI' ? 'checked' : '' }
                                        onChange={this.handleChange} 
                                    />
                                </div>
                                <div className="producto col d-none">
                                    <label>G95</label>
                                    <input 
                                        type="checkbox" 
                                        name="g95"
                                        value="product"
                                        checked={ this.state.g95 == 'SI' ? 'checked' : '' }
                                        onChange={this.handleChange} 
                                    />
                                </div>
                                <div className="producto col d-none">
                                    <label>G97</label>
                                    <input 
                                        type="checkbox" 
                                        name="g97"
                                        value="product"
                                        checked={ this.state.g97 == 'SI' ? 'checked' : '' }
                                        onChange={this.handleChange} 
                                    />
                                </div>
                                <div className="producto col d-none">
                                    <label>G98</label>
                                    <input 
                                        type="checkbox" 
                                        name="g98"
                                        value="product"
                                        checked={ this.state.g98 == 'SI' ? 'checked' : '' }
                                        onChange={this.handleChange} 
                                    />
                                </div>
                                <div className="producto col">
                                    <label>GR</label>
                                    <input 
                                        type="checkbox" 
                                        name="gr"
                                        value="product"
                                        checked={ this.state.gr == 'SI' ? 'checked' : '' }
                                        onChange={this.handleChange} 
                                    />
                                </div>
                                <div className="producto col">
                                    <label>GP</label>
                                    <input 
                                        type="checkbox" 
                                        name="gp"
                                        value="product"
                                        checked={ this.state.gp == 'SI' ? 'checked' : '' }
                                        onChange={this.handleChange} 
                                    />
                                </div>
                                <div className="producto col">
                                    <label>GLP</label>
                                    <input 
                                        type="checkbox" 
                                        name="glp"
                                        value="product"
                                        checked={ this.state.glp == 'SI' ? 'checked' : '' }
                                        onChange={this.handleChange} 
                                    />
                                </div>
                                <div className="producto col d-none">
                                    <label>GNV</label>
                                    <input 
                                        type="checkbox" 
                                        name="gnv"
                                        value="product"
                                        checked={ this.state.gnv == 'SI' ? 'checked' : '' }
                                        onChange={this.handleChange} 
                                    />
                                </div>
                            </div>
                            { errors.fuel.length > 0 && <span className='text-danger font-weight-bold'>{ errors.fuel } </span> }
                        </div>
                        {/* Select method */}
                        {/* <div className="form-group">
                            <label>¿Cómo quieres recibir tu tarjeta?</label>
                            <select
                                className="form-control"
                                name="method"
                                onChange={this.handleChange}
                            >
                                <option>Seleccionar</option>
                                {
                                    this.state.methods.map(method =>
                                        <option
                                            key={method._id}
                                        >
                                            {method.method}
                                        </option>)
                                }
                            </select>
                            { errors.method.length > 0 && <span className='text-danger font-weight-bold'>{ errors.method } </span> }
                        </div>
                        {
                            this.state.condition === 1 ? 
                                (
                                    <Fragment>
                                        <div className="form-group">
                                            <label>Dirección de Recojo</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="address"
                                                autoComplete="nope"
                                                onChange={this.handleChange}
                                                value={this.state.address}
                                                disabled={true}
                                            />
                                        </div>
                                    </Fragment>                                   
                                ) : 
                            this.state.condition === 3 ? 
                                (
                                    <Fragment>
                                        <div className="form-group">
                                            <label>Dirección de Entrega</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="address"
                                                autoComplete="nope"
                                                onChange={this.handleChange}
                                            />
                                            { errors.address.length > 0 && <span className='text-danger font-weight-bold'>{ errors.address } </span> }
                                        </div>
                                        <div className="form-group">
                                            <label>Referencia</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="reference"
                                                autoComplete="nope"
                                                onChange={this.handleChange}
                                            />
                                            { errors.reference.length > 0 && <span className='text-danger font-weight-bold'>{ errors.reference } </span> }
                                        </div>
                                        <div className="form-group">
                                            <label>¿Quien recibira la tarjeta?</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="responsable"
                                                autoComplete="nope"
                                                onChange={this.handleChange}
                                            />
                                            { errors.responsable.length > 0 && <span className='text-danger font-weight-bold'>{ errors.responsable } </span> }
                                        </div>
                                    </Fragment>                                    
                                ) 
                                :
                            this.state.condition === 2 ? 
                                (
                                    <Fragment>
                                        <div className="form-group">
                                            <label>EESS</label>
                                            <select
                                                className="form-control"
                                                name="eess"
                                                onChange={this.handleChange}
                                            >
                                                <option>Seleccionar EESS</option>
                                                {
                                                    this.state.eesss.map(eess =>
                                                        <option
                                                            key={eess.id_estacion} value={[eess.Nombre, eess.Dirección]}
                                                        >
                                                            {eess.Nombre}
                                                        </option>)
                                                }
                                            </select>
                                            { errors.eess.length > 0 && <span className='text-danger font-weight-bold'>{ errors.eess } </span> }
                                        </div>
                                        <div className="form-group">
                                            <label>Dirección de EESS</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="address"
                                                autoComplete="nope"
                                                value={this.state.address}
                                                disabled={true}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </Fragment>                                
                                ) : ''
                        } */}
                        <button 
                            type="submit" 
                            className="btn btn-primary float-right"
                            style={{backgroundColor : color2, border: 'none', borderRadius: '20px'}}
                            disabled={this.state.isDisabled}
                        >
                            <i className="fa fa-save p-2" aria-hidden="true"></i> { this.state.textButton }
                        </button>                   
                    </div>
                </form>
                <ToastContainer/>
                <Modal isOpen={this.state.abierto} style={{ position: 'absolute', top: '50%', left: '48%', transform: 'translate( -50%, -50%)', width: '90%' }}>
                    <ModalBody className='text-danger font-weight-bold'>
                        <i className="fa fa-times-circle-o text-danger mb-3" aria-hidden="true"></i>
                        <h5 style={{ textAlign: 'center', color: 'black' }}>Debe validar sus datos y <b>GUARDAR</b>.</h5>
                        <button onClick={this.cerrar_modal} className="btn btn-danger mt-3">Cerrar</button>
                    </ModalBody>
                </Modal>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>

                </div>
            </div>
        )
    }
}
