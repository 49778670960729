import React, { Component } from 'react';
import axios from 'axios';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
//import logo_cabify2 from '../img/Cabify-logo-purple.png';
//import logo_chazki from '../img/logo-chazki-azul.png';
import logo_chazki from '../img/chk.png';
import logo_pedidos_ya from '../img/pya.png';
import logo_uber from '../img/uber.png';
import logo_null from '../img/null.png';
//import logo_pedidos_ya from '../img/logo_pedidosya.png';
//import logo_cabify from '../img/Cabify-Logo-Moradul-RGB.png';
import logo_cabify from '../img/cbf.png';
import logo_jtours from '../img/j&jtours3.png';
import logo_jtours2 from '../img/j&jtours2.png';
import logo_redcol from '../img/redcol.png';
import logo_3a from '../img/3a.png';
import logo_3a2 from '../img/3a2.png';
import logo_redcol2 from '../img/redcol2.png';
import logo_tmonterrico from '../img/tmonterrico.png';
import logo_rapired from '../img/rapired.png';
import logo_rapired2 from '../img/rapired2.png';
import logo_mimotorizado from '../img/mimotorizado.png';
import logo_mimotorizado2 from '../img/mimotorizado2.png';
import logo_motobros from '../img/motobros.png';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';

var distritos = []

export default class EessList extends Component {

    constructor (props) {
        super(props);
        this.state = {
            datasourse: [],
            filtro_distrito: '',
        }
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    getEess = async () => {
        let code = localStorage.getItem("codigo");
        let fecha_registro = localStorage.getItem("fecha_registro")
        if(code == '' || code == null){
            window.location.href = "./";
        } else if(fecha_registro === 'null'){
            window.location.href = "./view";
        } else{
            const res = await axios.get(process.env.REACT_APP_BASE_URL + '/api/eess');
            this.setState({ datasourse: res.data });
            res.data.map(distrito => {
                if(distritos.indexOf(distrito.DistritoNombre) == -1){
                    distritos.push(distrito.DistritoNombre);
                }   
            });
            distritos.sort();
            distritos.unshift('Todo');
            this.setState({ filtro_distrito: distritos[0] });
            // Array.prototype.unicos = function () {
            //     return [... new Set(this)];
            // }
            // distritos.unicos();
        }  
    };

    componentDidMount = () => {
        this.getEess();
    };

    logout = async () => {
        // setUserData({
        //     token: undefined,
        //     user: undefined
        // });
        window.location.href = "./";
        localStorage.setItem('auth-token', '');
        localStorage.setItem("placa", '');
        localStorage.setItem("codigo", '');
        localStorage.setItem("id_placa", '');
        //localStorage.setItem("consorcio", '');
        
    }

    render() {
        var lista_distritos = distritos;
        lista_distritos.forEach( function(valor, indice, array) {
            //console.log(array[1]);
        });
        
        var placa = localStorage.getItem("placa");
        var consorcio = localStorage.getItem("consorcio");
        var logo;
        var logo2;
        var color;
        var color2;
        var colorB;
        var ancho;
        var largo;
        var item = 'd-none';
        var menu_alt = 80;
        var link = process.env.REACT_APP_BASE_URL;
        if(consorcio == '20548704261'){
            logo = logo_cabify;
            logo2 = logo_cabify;
            color = '#7145d6';
            color2 = '#7145d6';
            colorB = '#7145d6';
            ancho = 100;
            largo = 30;
            item = '';
        } else if(consorcio == '20485423444'){
            logo = logo_chazki;
            logo2 = logo_chazki;
            color = '#2778d4';
            color2 = '#2778d4';
            colorB = '#2778d4';
            ancho = 110;
            largo = 30;
        } else if(consorcio == '20556082708'){
            logo = logo_pedidos_ya;
            logo2 = logo_pedidos_ya;
            color = '#e30613';
            color2 = '#e30613';
            colorB = '#e30613';
            ancho = 140;
            largo = 30;
        } else if(consorcio == '20556126764'){
            logo = logo_uber;
            logo2 = logo_uber;
            color = '#000000';
            color2 = '#000000';
            colorB = '#000000';
            ancho = 140;
            largo = 30;
        } else if(consorcio == '20502926731'){
            logo = logo_tmonterrico;
            logo2 = logo_tmonterrico;
            color = '#000000';
            color2 = '#000000';
            colorB = '#000000';
            ancho = 140;
            largo = 30;
            menu_alt = 45;
        } else if(consorcio == '20492680924'){
            logo = logo_rapired;
            logo2 = logo_rapired2;
            color = '#144224';
            color2 = '#144224';
            colorB = '#144224';
            ancho = 140;
            largo = 30;
            menu_alt = 150;
        } else if(consorcio == '20505880171'){
            logo = logo_redcol;
            logo2 = logo_redcol2;
            color = '#EBE9E9';
            color2 = '#d4ac44';
            colorB = '#EBE9E9';
            ancho = 140;
            largo = 30;
        } else if(consorcio == '20602110673'){
            logo = logo_mimotorizado;
            logo2 = logo_mimotorizado2;
            color = '#e30613';
            color2 = '#e30613';
            colorB = '#e30613';
            
            ancho = 140;
            largo = 35;
            menu_alt = 180;
        } else if(consorcio == '20550876796'){
            logo = logo_jtours;
            logo2 = logo_jtours2;
            color = '#848cbc';
            color2 = '#848cbc';
            colorB = '#848cbc';
            ancho = 140;
            largo = 30;
            menu_alt = 45;
        } else if(consorcio == '20512829652'){
            logo = logo_3a;
            logo2 = logo_3a2;
            color = '#EBE9E9';
            color2 = '#ff040c';
            colorB = '#EBE9E9';
            ancho = 140;
            largo = 30;
            menu_alt = 150;
        } else if(consorcio == '20610741089'){
            logo = logo_motobros;
            logo2 = logo_motobros;
            color = '#000000';
            color2 = '#000000';
            colorB = '#000000';
            ancho = 110;
            largo = 30;
        } else{
            logo = logo_null;
            logo2 = logo_null;
            color = '#000000';
            color2 = '#000000';
            colorB = '#000000';
            ancho = 70;
            largo = 30;
        }
        return(
            <div className="col-md-12">
                <nav id="colorlib-main-nav" role="navigation">
                    <a href="#" className="js-colorlib-nav-toggle colorlib-nav-toggle active"><i></i>
                    </a>
                    <div className="js-fullheight colorlib-table">
                        <div className="colorlib-table-cell js-fullheight">
                            <div className="row no-gutters">
                                <div className="col-md-12 text-center">
                                    <h1 className="mb-4">
                                        <a href="" className="logo"><img src={logo2} style={{width: 'auto', height: menu_alt}} alt=""/></a>
                                    </h1>
                                    <ul className='t-letter'>
                                        <li><a href="./view"><span>Mi Perfil</span></a></li>
                                        <li><a href="./prices_list_consortium"><span>Precios</span></a></li>
                                        <li className="active"><a href="./eess_list_consortium"><span>EESS</span></a></li>
                                        {/* <li className={item}><a href="./bono"><span>Bono</span></a></li> */}
                                        <li><a href="" onClick={this.logout}><span>Salir</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <div id="colorlib-page">
                    <header style={{backgroundColor: color}}>
                        <div className="container">
                            <div className="colorlib-navbar-brand">
                                <a style={{borderRadius: '10px', backgroundColor: colorB}} className="colorlib-logo" href="">
                                    <img src={logo} style={{width: 'auto', height: largo}} alt=""/>
                                </a>
                            </div>
                            <a href="" className="js-colorlib-nav-toggle colorlib-nav-toggle"><i></i>
                            </a>
                        </div>
                    </header>
                    <section className="hero-wrap js-fullheight">
                        <div className="container px-0">
                            <div className="row no-gutters slider-text js-fullheight align-items-top justify-content-center" data-scrollax-parent="true">
                                <div className="col-md-12 ftco-animate text-center">
                                    <div className="">
                                        <div className="bloque">
                                            <div className="row" style={{marginTop: '70px'}}>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <h5 className='t-letter'><b>EESS Afiliadas</b></h5>
                                                    </div>                    
                                                </div>
                                                <br/>
                                                <div className="col-md-12 t-letter">
                                                    <div className="options">
                                                        <div className="option">
                                                            <small>Seleccionar Distrito</small>
                                                            <SelectBox
                                                                items={distritos}
                                                                value={this.state.filtro_distrito}
                                                                onValueChanged={this.onValueChanged}
                                                            />
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <DataGrid
                                                        id="gridContainer"
                                                        ref={ref => this.dataGrid = ref}
                                                        dataSource={this.state.datasourse}
                                                        showBorders={true}
                                                        showRowLines={true}
                                                    >
                                                    <Column
                                                        dataField="Nombre"
                                                        caption="EESS"
                                                        width={"100%"}
                                                        cellRender={cellRender}
                                                    />
                                                    </DataGrid>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }

    onValueChanged({ value }) {
        const dataGrid = this.dataGrid.instance;
        //console.log(dataGrid);
        if (value === 'Todo') {
            dataGrid.clearFilter();
        }
        else {
            dataGrid.filter(['DistritoNombre', 'contains', value]);
        }
        this.setState({
            filtro_distrito: value
        });
    }
}

function cellRender(data) {
    return <table style={{width: '100%'}}>
                <tbody>
                    <tr className="main-row" style={{fontSize: '16px', fontWeight: 'bold'}}>
                    <td><a href={data.data.Ubicacion} target="_blank" rel="noopener">{data.data.Nombre}</a></td>
                    </tr>
                    <tr className="notes-row" style={{fontSize: '14px'}}>
                        <td style={{ color: '#A6ACAF' }}>Dirección : {data.data.Dirección}</td>
                    </tr>
                    <tr className="notes-row" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                    {
                        data.data.Productos.split(",").sort().map((product, index) =>
                            <td
                                style={{ padding: '4px 10px 2px 0' }}
                            >
                                <div id={ product } style={{ padding: '4px 6px', color: 'white', borderRadius: '4px', fontWeight: '500' }}>
                                    {product}
                                </div>
                            </td>
                        )
                    }
                    </tr>
                </tbody>
            </table>           
    ;
}