import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import UserContext from '../context/UserContext';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import DataGrid, { Column, Lookup, Editing, FilterRow, Export } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { locale } from "devextreme/localization";

export default function FormsList() {
    const [forms, setForms] = useState([]);

    const { setUserData } = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
        let token = localStorage.getItem('auth-token');
        if (token === null || token === '') {
            history.push('/')
        } else {
            if (forms.length === 0) {
                getForms();
                locale("es");
            }           
        }    
               
    });

    const statement = [
        {
            "ID": "Pendiente",
            "Estado": "Pendiente"
        },
        {
            "ID": "Atendido",
            "Estado": "Atendido"
        },
        {
            "ID": "Cerrado",
            "Estado": "Cerrado"
        }
    ]

    const getForms = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + '/api/forms');
        setForms(res.data);
    }

    function logout() {
        setUserData({
            token: undefined,
            user: undefined
        });
        localStorage.setItem('auth-token', '');
        history.push('/');
    }

    const updateForm = async (id, statement, card, operations) => {
        const newForm = {
            statement,
            card,
            operations
        };
        await axios.put(process.env.REACT_APP_BASE_URL + '/api/forms/' + id, newForm);
    }

    function getNewForm(data) {
        let statement = '';
        let card = '';
        let operations = '';
        if (data.newData.statement !== undefined) {
            statement = data.newData.statement;
            if (data.key.card !== undefined) {
                if (data.key.card !== '') {
                    card = data.key.card;
                } else {
                    card = '';
                }
            } else {
                card = '';
            }
            if (data.key.operations !== undefined) {
                if (data.key.operations !== '') {
                    operations = data.key.operations;
                } else {
                    operations = '';
                }
            } else {
                operations = '';
            }
        }
        if (data.newData.card !== undefined) {
            statement = data.key.statement;
            card = data.newData.card;
            if (data.key.operations !== undefined) {
                if (data.key.operations !== '') {
                    operations = data.key.operations;
                } else {
                    operations = 'Pendiente';
                }
            } else {
                operations = 'Pendiente';
            }
        }
        updateForm(data.key._id, statement, card, operations)
    }

    const overrideOnValueChanged = (e) => {
        if (e.parentType === 'dataRow' && e.dataField === 'card') {
            e.editorOptions.disabled = e.row.data && e.row.data.eess === "";
        }
    }

    return (
        <div className="continer m-3">
            <div className="row">
                <div className="col-md-12 mb-2">
                    <Button
                        width={'auto'}
                        icon="fa fa-sign-out mx-2 text-light"
                        text="CERRAR SESION"
                        className="btn btn-primary btn-sm float-right"
                        stylingMode="contained"
                        onClick={logout}
                    />
                </div>
                <div className="col-md-12">
                    <DataGrid
                            dataSource={forms}
                            showBorders={true}
                            showRowLines={true}
                            onRowUpdating={getNewForm}
                            onEditorPreparing={overrideOnValueChanged}
                        >
                        <FilterRow 
                            visible={true}
                            applyFilter={true} 
                        />
                        <Editing
                            mode="cell"
                            allowUpdating={true} 
                        />
                        <Column
                            dataField="document"
                            caption="Documento"
                            width={100}
                            allowEditing={false}
                        />
                        <Column
                            dataField="name"
                            caption="Nombre y Apellido"
                            width={250}
                            allowEditing={false}
                        />
                        <Column
                            dataField="email"
                            caption="Correo Electrónico"
                            width={250}
                            allowEditing={false}
                        />
                        <Column
                            dataField="plate"
                            caption="Placa"
                            width={100}
                            allowEditing={false}
                        />
                        <Column
                            dataField="card"
                            caption="Tarjeta"
                            width={150}
                            allowEditing={true}
                        />
                        <Column
                            dataField="phone"
                            caption="Teléfono"
                            width={150}
                            allowEditing={false}
                        />
                        <Column
                            dataField="d2"
                            caption="D2"
                            width={80}
                            allowEditing={false}
                        />
                        <Column
                            dataField="g84"
                            caption="G84"
                            width={80}
                            allowEditing={false}
                        />
                        <Column
                            dataField="g90"
                            caption="G90"
                            width={80}
                            allowEditing={false}
                        />
                        <Column
                            dataField="g95"
                            caption="G95"
                            width={80}
                            allowEditing={false}
                        />
                        <Column
                            dataField="g97"
                            caption="G97"
                            width={80}
                            allowEditing={false}
                        />
                        <Column
                            dataField="g98"
                            caption="G98"
                            width={80}
                            allowEditing={false}
                        />
                        <Column
                            dataField="glp"
                            caption="GLP"
                            width={80}
                            allowEditing={false}
                        />
                        <Column
                            dataField="gnv"
                            caption="GNV"
                            width={80}
                            allowEditing={false}
                        />
                        <Column
                            dataField="method"
                            caption="Metodo"
                            width={250}
                            allowEditing={false}
                        />
                        <Column
                            dataField="eess"
                            caption="EESS"
                            width={200}
                            allowEditing={false}
                        />                        
                        <Column
                            dataField="address"
                            caption="Dirección Entrega"
                            width={300}
                            allowEditing={false}
                        />
                        <Column
                            dataField="reference"
                            caption="Referencia"
                            width={300}
                            allowEditing={false}
                        />
                        <Column
                            dataField="responsable"
                            caption="Responsable"
                            width={250}
                            allowEditing={false}
                        />
                        <Column
                            dataField="date"
                            caption="Fecha Registro"
                            dataType="date"
                            format="d/MM/yyyy hh:mm:ss aa"
                            width={200}
                            allowEditing={false}
                        />
                        <Column 
                            dataField="statement" 
                            caption="Estado" 
                            width={100}
                        >
                            <Lookup 
                                dataSource={statement} 
                                valueExpr="ID" 
                                displayExpr="Estado" 
                            />
                        </Column>
                        <Column
                            dataField="operations"
                            caption="Operaciones"
                            width={100}
                            allowEditing={false}
                        />
                        <Export enabled={true} />
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}


