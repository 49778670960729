import React, { Component, Fragment } from 'react';
import axios from 'axios';
import logo_cabify from '../img/Cabify-logo-purple.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class DeliverCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eesss: [],
            cards: [],
            id: '',
            statement: '',
            eess: '',
            card: '',
            textButton: 'ENTREGAR',
            isDisabled: false,
            errors: {
                eess: '',
                card: ''
            }
        }
    };

    notify = (message, type) => {
        if (type === 'success') {
            toast.success(message, { position: toast.POSITION.TOP_RIGHT });
        } else if (type === 'error') {
            toast.error(message, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    getCards = async eess => {
        let array = [];
        const res = await axios.get(process.env.REACT_APP_BASE_URL + '/api/forms/' + eess);
        res.data.map( value => {
            if (value.card !== undefined) {
                if (value.card !== '') {
                    if (value.operations === 'Pendiente') {
                        if (value.card !== "") {
                            array.push({
                                id: value._id,
                                statement: value.statement,
                                card: value.card
                            })
                        }                       
                    }                    
                }
            }
        })
        this.setState({ cards: array });
    }

    getStations = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + '/api/eess');
        this.setState({ eesss: res.data });
    };

    componentDidMount = () => {
        this.getStations();
    };

    handleChange = e => {
        const { name, value, maxLength } = e.target;
        if (name === 'eess') { 
            this.getCards(value) 
            this.setState({ eess: value })
        };
        if (name === 'cards') { 
            let array = value.split(',');
            this.setState({ id: array[0] });
            this.setState({ statement: array[1] });
            this.setState({ card: array[2] });
        }
    }

    validate = () => {
        const state = this.state;
        let errors = this.state.errors;
        let valid = true;
        if (state.eess === 'Seleccionar EESS' || state.eess === '') { errors.eess = 'Ingresar una EESS valida *'} else { errors.eess = '' }
        if (state.card === 'Seleccionar Tarjeta' || state.card === '') { errors.card = 'Ingresar una Tarjeta valida *'} else { errors.card = '' }
        this.setState({ errors });
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        return valid;
    }

    updateForm = async () => {
        const newForm = {
            statement: this.state.statement,
            card: this.state.card,
            operations: 'Entregado'
        };
        await axios.put(process.env.REACT_APP_BASE_URL + '/api/forms/' + this.state.id, newForm);
        window.location.href = "/success_four";
    }

    onSubmit = async e => {
        e.preventDefault();
        this.setState({ isDisabled: true, textButton: 'ENTREGANDO...' });
        if (this.validate()) {
            this.updateForm();
            
        } else {
            this.setState({ isDisabled: false, textButton: 'ENTREGAR' });
            this.notify(' Campos incompletos o invalidos','error');
        }
    }

    render() {
        const { errors } = this.state;
        return (
            <div className="col-md-12">
                <form onSubmit={this.onSubmit}>
                    <div className="container p-2">
                        <div className="form-group logo">
                            <img src={logo_cabify} alt=""/>
                        </div>
                        <div className="form-group">
                            <h4>Formulario de Entrega</h4>
                        </div>
                        <br />
                        <div className="form-group">
                            <label>EESS</label>
                            <select
                                className="form-control"
                                name="eess"
                                onChange={this.handleChange}
                            >
                                <option>Seleccionar EESS</option>
                                {
                                    this.state.eesss.map(eess =>
                                        <option
                                            key={eess.id_estacion} value={eess.Nombre}
                                        >
                                            {eess.Nombre}
                                        </option>)
                                }
                            </select>
                            { errors.eess.length > 0 && <span className='text-danger font-weight-bold'>{ errors.eess } </span> }
                        </div>
                        <div className="form-group">
                            <label>Tarjetas</label>
                            <select
                                className="form-control"
                                name="cards"
                                onChange={this.handleChange}
                            >
                                <option>Seleccionar Tarjeta</option>
                                {
                                    this.state.cards.map(card =>
                                        <option
                                            value={[card.id,card.statement,card.card]}
                                        >
                                            {card.card}
                                        </option>)
                                }
                            </select>
                            { errors.card.length > 0 && <span className='text-danger font-weight-bold'>{ errors.card } </span> }
                        </div>
                        <button 
                            type="submit" 
                            className="btn btn-primary float-right"
                            disabled={this.state.isDisabled}
                        >
                            <i className="fa fa-credit-card mx-2" aria-hidden="true"></i> { this.state.textButton }
                        </button>                   
                    </div>
                </form>
                <ToastContainer/>
            </div>
        )
    }
}
